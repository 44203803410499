import React from 'react';
import data from '../data.js';
// import log from 'loglevel';
// import remote from 'loglevel-plugin-remote';

const ru_locale = {
  1: 'Что-то пошло не так в этой части нашего сайта. Пожалуйста, попробуйте перезагрузить страницу или обратитесь к нам по адресу support@fastline.aero.'
}
const uk_locale = {
1: 'Щось пішло не так у цій частині нашого сайту. Будь ласка, спробуйте перезавантажити сторінку або зверніться до нас за адресою support@fastline.aero.'
}
const en_locale = {
1: 'Something went wrong in this part of our website. Please try to reload it or email us at support@fastline.aero.'
}
const location = window.location.pathname;
const lang = data.languages.some(l => l === location.substr(1, 2)) ? location.substr(1, 2) : data.lang_default;
const locale = lang === 'ru' ? ru_locale : (lang === 'uk' ? uk_locale : en_locale);

export default class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }


  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, info) {
  //   // You can also log the error to an error reporting service
  //   // logErrorToMyService(error, info);
  //   const customJSON = log => ({
  //     msg: log.message,
  //     level: log.level.label,
  //     stacktrace: log.stacktrace
  //    });

  //    remote.apply(log, { format: customJSON, url: '/logger' });
     
  //    log.enableAll();
     
  //    log.info(info);
  //    log.error(error);
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <p className='container font-weight-bold font-italic m-3'>{locale['1']}</p>;
    }

    return this.props.children; 
  }
}