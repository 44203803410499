import React, {useEffect, lazy, Suspense } from 'react';
import HomeMainBanner from '../components/HomeMainBanner';
import HomeBooking from '../components/HomeBooking';
import ErrorBoundary from '../components/ErrorBoundary';

const HomeStatisticsBlock = lazy(() => import('../components/HomeStatisticsBlock'));
const HomeContacts = lazy(() => import('../components/HomeContacts'));
const HomeOurServices = lazy(() => import('../components/HomeOurServices'));

// const DoubleBannerAd = lazy(() => import('../components/DoubleBannerAd'));
// const SingleBannerAd = lazy(() => import('../components/SingleBannerAd'));

export default function HomeScreen(props) {
  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo(0, 0);
    }
  }, []);
    return (
      <div>
        <ErrorBoundary><Suspense><HomeMainBanner></HomeMainBanner></Suspense></ErrorBoundary>
        <ErrorBoundary><Suspense><HomeStatisticsBlock></HomeStatisticsBlock></Suspense></ErrorBoundary>
        {/* <DoubleBannerAd></DoubleBannerAd> */}
        <ErrorBoundary><Suspense><HomeOurServices></HomeOurServices></Suspense></ErrorBoundary>
        <ErrorBoundary><Suspense><HomeBooking></HomeBooking></Suspense></ErrorBoundary>
        {/* <SingleBannerAd></SingleBannerAd> */}
        <ErrorBoundary><Suspense><HomeContacts></HomeContacts></Suspense></ErrorBoundary>
      </div>
    )
}