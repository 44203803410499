import React from 'react';
import { Link } from 'react-router-dom';
import data from '../data.js';

export default function ErrorPage() {
  const ru_locale = {
    1: 'Ошибка 404',
    2: 'Это не та страница, которую вы ищите...',
    3: 'Вернуться на главную'
}
const uk_locale = {
  1: 'Помилка 404',
  2: 'Це не та сторінка, яку ви шукаєте...',
  3: 'Повернутися на головну'
}
const en_locale = {
  1: '404 error',
  2: 'It\'s not the page you are looking for...',
  3: 'Back to Home page'
}
  const location = window.location.pathname;
  const lang = data.languages.some(l => l === location.substr(1, 2)) ? location.substr(1, 2) : data.lang_default;
  const locale = lang === 'ru' ? ru_locale : (lang === 'uk' ? uk_locale : en_locale);

    return (
      // <div className="error-block-outer-wrapper">
      //  <div className="error-block-wrapper">
        <div className="block_title container block-title-error">
        <p className="block_title-tag">{locale['1']}</p>
        <h3 className="block_title-name">{locale['2']}</h3>
        <Link to={'/'+lang} className="back-home btn">&#x2190; {locale['3']}</Link>
      </div>
      // </div>
      // <div className="error-downline"></div>
      // </div>
     
    )
}