import React, { useEffect, useState, lazy, Suspense } from 'react';
import data from '../data.js';
import ErrorBoundary from '../components/ErrorBoundary';
// import smoothscroll from 'smoothscroll-polyfill';

const BookingMainContent = lazy(() => import('./BookingMainContent'));
const BookingCart = lazy(() => import('./BookingCart'));
const SdfLink = lazy(() => import('./SdfLink'));
const PromosBlock = lazy(() => import('./PromosBlock'));
const CartModal = lazy(() => import('./CartModal'));

export default function HomeBooking() {
    const ru_locale = {
        1: 'Создайте собственную зону комфорта в аэропорту',
        2: 'Забронируйте Fastline онлайн',
        3: 'Выберите услугу',
        4: 'Covid-19 Тест',
        5: 'Лаунж зона',
        6: 'Специальние предложения'
    }
    const uk_locale = {
      1: 'Створіть власну зону комфорту в аеропорту',
      2: 'Забронюйте Fastline онлайн',
      3: 'Оберіть послугу',
      4: 'Covid-19 Тест',
      5: 'Лаунж зона',
      6: 'Спеціальні пропозиції'
    }
    const en_locale = {
      1: 'Create your comfort zone at the airport',
      2: 'Book Fastline online',
      3: 'Choose service',
      4: 'Covid-19 Test',
      5: 'Business Lounge',
      6: 'Special offers'
    }
    const location = window.location.pathname;
  const lang = data.languages.some(l => l === location.substr(1, 2)) ? location.substr(1, 2) : data.lang_default;
  const locale = lang === 'ru' ? ru_locale : (lang === 'uk' ? uk_locale : en_locale);
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : data.currency_default;
    const [modalShow, setModalShow] = useState(false);
    const [showFlForm, updateFlForm] = useState(false);
    const [showSoForm, updateSoForm] = useState(false);
    const [currentContent, setCurrentContent] = useState('Fastline');
    const [cart, updateCart] = useState({});
    const [menuActive, updateMenu] = useState('Fastline');
    const [cartActive, showCart] = useState(false);
    const [removeEvent, updateRemoveEvent] = useState(false);
    const flOptions = [];
    let covBlOptions = [];
    let allOptions = data.fastlineOptions.concat(data.businessLoungeOptions.concat(data.covidOptions));
    allOptions.map( (option, index) => {
        if (option.name === "Fastline Premium") {
            let optTemp = {...option};
            optTemp.price = option.price[currency];
            optTemp.kids_price = option.kids_price[currency]; 
            optTemp.flight = option.flight[lang];
            optTemp.totalSum = option.totalSum[currency];
            optTemp.currency = currency;
            optTemp.airport = option.airport[lang];
            allOptions[index] = optTemp;
        }
        if (option.name === "Covid-19 Test") {
            let optTemp = {...option};
            optTemp.pcrPrice_kbp = option.pcrPrice_kbp[currency];
            optTemp.pcrPrice_ods = option.pcrPrice_ods[currency]; 
            optTemp.antigenPrice = option.antigenPrice[currency];
            optTemp.totalSum = option.totalSum[currency];
            optTemp.currency = currency;
            optTemp.airport = option.airport[lang];
            allOptions[index] = optTemp;
        }
        if (option.name === "Business Lounge") {
            let optTemp = {...option};
            optTemp.minPrice = option.minPrice[currency];
            optTemp.price = option.price[currency]; 
            optTemp.kids_minPrice = option.kids_minPrice[currency];
            optTemp.kids_price = option.kids_price[currency];
            optTemp.flight = option.flight[lang];
            optTemp.totalSum = option.totalSum[currency];
            optTemp.currency = currency;
            optTemp.airport = option.airport[lang];
            allOptions[index] = optTemp;
        }
    })
    data.covidOptions.concat(data.businessLoungeOptions).map((item, index) => {
        if (!(item.name==="Business Lounge" &&  Object.keys(cart).find(x =>x==="Fastline Business"))) {
            covBlOptions.push(item.name);
        }
    });
    data.fastlineOptions.map((item, index) => flOptions.push(item.name));

    let notInCartListFl = (!Object.keys(cart).some(x => flOptions.indexOf(x) >= 0)) ? 'Fastline Premium' : '';
    let notInCartListCovLoun = [];
     covBlOptions.map((item, index) => {
        if (!Object.keys(cart).find(x =>x===item))  {
         notInCartListCovLoun.push(item);
        }
    });
    if (notInCartListFl) {
        notInCartListCovLoun.unshift(notInCartListFl);
    }
    let notInCartList = notInCartListCovLoun.map((item) => {
        return allOptions.filter(obj => { return obj.name === item});
    });
    let notInCartListFinal = [].concat.apply([], notInCartList);
    let promosBlockListFinal = [];
    
    // if (cart && Object.keys(cart).length > 0 && cart.constructor === Object && cart['Fastline Basic + Covid-19 Test'] && cart['Fastline Comfort']) {
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='8'));
    //     if (window.matchMedia("(min-width: 768px)").matches) {
    //         promosBlockListFinal.push(data.promosOptions.find(x => x._id==='11'));
    //     }
    // } else if (cart && Object.keys(cart).length > 0 && cart.constructor === Object && cart['Fastline Basic + Covid-19 Test']) {
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='10'));
    //     if (window.matchMedia("(min-width: 768px)").matches) {
    //         promosBlockListFinal.push(data.promosOptions.find(x => x._id==='11'));
    //     }
    // } else if (cart && Object.keys(cart).length > 0 && cart.constructor === Object && cart['Fastline Comfort']) {
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='8'));
    //     if (window.matchMedia("(min-width: 768px)").matches) {
    //         promosBlockListFinal.push(data.promosOptions.find(x => x._id==='11'));
    //     }
    // } else if (cart && Object.keys(cart).length > 0 && cart.constructor === Object && cart['Fastline Basic']) {
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='9'));
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='10'));
    // } else if (cart && Object.keys(cart).length > 0 && cart.constructor === Object && cart['Covid-19 Test']) {
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='10'));
    //     if (window.matchMedia("(min-width: 768px)").matches) {
    //         promosBlockListFinal.push(data.promosOptions.find(x => x._id==='11'));
    //     }
    // } else if (cart && Object.keys(cart).length > 0 && cart.constructor === Object && cart['Business Lounge']) {
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='9'));
    //     if (window.matchMedia("(min-width: 768px)").matches) {
    //         promosBlockListFinal.push(data.promosOptions.find(x => x._id==='11'));
    //     }
    // } else if (((cart && Object.keys(cart).length > 0 && cart.constructor === Object) && (cart['Fastline Kids'] || cart['Fastline Premium'] || cart['Fastline Care'] || cart['Fastline Business'])) || (cart && Object.keys(cart).length > 0 && cart.constructor === Object && cart['Fastline Comfort'])) {
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='8'));
    //     if (window.matchMedia("(min-width: 768px)").matches) {
    //         promosBlockListFinal.push(data.promosOptions.find(x => x._id==='11'));
    //     }
    // } else {
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='9'));
    //     promosBlockListFinal.push(data.promosOptions.find(x => x._id==='10'));
    // }
    promosBlockListFinal.push(data.promosOptions.find(x => x._id==='10'));
    promosBlockListFinal.push(data.promosOptions.find(x => x._id==='8'));
    
    // local storage block
    let localCart;
    
      useEffect(() => {
        if (localStorage.getItem("cart")) {
            localCart = JSON.parse(localStorage.getItem("cart"));
        }
          if (localCart && Object.keys(localCart).length > 0 && localCart.constructor === Object) {   
            updateCart(localCart);
            showCart(true);
        }
      }, []);

    function addToCart(details) {
        let newCart = {...cart};
        if (!Array.isArray(details)) {
            let newDetails = {...details};
            // if (newDetails['full_description']) {
            //     delete newDetails['full_description'];
            // }
            // if (newDetails['full_descr_opt']) {
            //     delete newDetails['full_descr_opt'];
            // }
            newCart[newDetails.name] = newDetails;
        } else {
            newCart[details[0].name] = details[0];
            if (!cart[details[1].name]) {
                newCart[details[1].name] = details[1];
            }
        }
        updateCart(newCart);
        showCart(true);

        let cartString = JSON.stringify(newCart);
        localStorage.setItem('cart', cartString);
    }

    function removeFromCart(line) {
        let newCart = {...cart};
        delete newCart[line.name];
        if (line.name === 'Fastline Basic' && newCart && Object.keys(newCart).length > 0 && newCart.constructor === Object && newCart['Business Lounge']) {
            newCart['Business Lounge'].totalSum = data.businessLoungeOptions[0].minPrice;
        } 
        updateCart(newCart);

        let cartString = JSON.stringify(newCart);
        localStorage.setItem('cart', cartString);
        updateRemoveEvent(true);

        if (newCart && Object.keys(newCart).length > 0 && newCart.constructor === Object) {
            showCart(true);
        }  else {
            localStorage.removeItem('cart');
            showCart(false);
            if (modalShow) { setModalShow(false); }
        }

        if (localStorage.getItem('checkout')) {
            let localCheck = localStorage.getItem('checkout');
            let localCheckParsed = JSON.parse(localCheck);
            if (localCheckParsed[line.name]) {
                delete localCheckParsed[line.name];
            };
            localStorage.setItem('checkout', JSON.stringify(localCheckParsed));
        }
    }

    function loadContent(value) {
        setCurrentContent(value);
        
        updateMenu(value);
        if (modalShow) { setModalShow(false) };
    }

    function loadContentFromCart(name, id) {
        if (!(name === 'Fastline' || name === 'Covid-19 Test' || name === 'Business Lounge')) {
            name = 'SpecOffers';
        }
        if (id && name==="Fastline") {
            loadContent(name);
            updateFlForm(id);
        } else if (id) {
            loadContent(name);
            updateSoForm(id);
        }
    }

    // function scroll(hash) {
    //     smoothscroll.polyfill();
    //     const el = hash && document.getElementById(hash);
    //     el.scrollIntoView({behavior: "smooth"});
    //   }
    
    return (
        <Suspense fallback={
            <div className="d-flex justify-content-center my-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>}>
            <div className="booking-block-wrap">
        <div className="booking-block container px-4" id="booking-block">
        <div className="block_title">
            <p className="block_title-tag">{locale['1']}</p>
            <h3 className="block_title-name">{locale['2']}</h3>
        </div>
        <div className="booking-block_main">
            <div className="booking-block_aside">
            <ErrorBoundary><div className="booking-block_menu">
                <span>{locale['3']}</span>
                <ul>
                    <li onClick={()=>{loadContent('Fastline');updateFlForm(false);updateSoForm(false);}} className={menuActive==="Fastline" ? "active" : null} id="fl_menu_btn">Fastline</li>
                    <li onClick={()=>{loadContent('Covid-19 Test');updateFlForm(false);updateSoForm(false);}} className={menuActive==="Covid-19 Test" ? "active" : null} id="covid_menu_btn">{locale['4']}</li>
                    <li onClick={()=>{loadContent('Business Lounge');updateFlForm(false);updateSoForm(false);}} className={menuActive==="Business Lounge" ? "active" : null} id="bl_menu_btn">{locale['5']}</li>
                    <li onClick={()=>{loadContent('SpecOffers');updateFlForm(false);updateSoForm(false);}} className={menuActive==="SpecOffers" ? "active" : null} id="so_menu_btn">{locale['6']}</li>
                </ul>
            </div></ErrorBoundary>
            {window.matchMedia("(min-width: 768px)").matches ?
                <ErrorBoundary><BookingCart content={cart} notInCart={notInCartListFinal} cartActive={cartActive} removeFromCart={removeFromCart} loadContent={(name, id)=>loadContentFromCart(name, id)} add={addToCart}></BookingCart></ErrorBoundary>
                : null
            }
            <ErrorBoundary><SdfLink class="sdf_link sdf_link_lg_screen"></SdfLink></ErrorBoundary>
            </div>
            <div className="booking-block_content">
               <ErrorBoundary>
                   <BookingMainContent content={currentContent} showFlForm={[showFlForm, (val)=>updateFlForm(val)]} showSoForm={[showSoForm, (val)=>updateSoForm(val)]} cart={cart} removeFromCart={removeEvent} onClick={addToCart} setModalShow={(val)=>setModalShow(val)}></BookingMainContent>
                </ErrorBoundary>
                <ErrorBoundary><PromosBlock blocksList={promosBlockListFinal} loadContent={(name, id)=>loadContentFromCart(name, id)}></PromosBlock></ErrorBoundary>
                <ErrorBoundary><SdfLink class="sdf_link sdf_link_sm_screen"></SdfLink></ErrorBoundary>
            </div>
        </div>
        </div>
        <ErrorBoundary><CartModal show={modalShow} content={cart} notInCart={notInCartListFinal} cartActive={cartActive} removeFromCart={removeFromCart} loadContent={(name, id)=>loadContentFromCart(name, id)} add={addToCart} onHide={() => setModalShow(false)}/></ErrorBoundary>
        </div>
      </Suspense>
    )
}
