import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import data from '../data.js';

export default function HomeMainBanner() {
   const location = window.location.pathname;
   const lang = data.languages.some(l => l === location.substr(1, 2)) ? location.substr(1, 2) : data.lang_default;

    const ru_locale = {
        1: 'Обеспечиваем комфорт в аэропортах и сохраняем время',
        2: 'Лаунж зона',
        3: 'Covid-19 Тест',
        4: 'Войти в зону комфорта'
    }
    const uk_locale = {
        1: 'Забезпечуємо комфорт в аеропортах та заощаджуємо час',
        2: 'Лаунж зона',
        3: 'Covid-19 Тест',
        4: 'Увійти в зону комфорту'
    }
    const en_locale = {
        1: 'We provide comfort and save your time at the airports',
        2: 'Business Lounge',
        3: 'Covid-19 Test',
        4: 'Enter the comfort zone'
    }
    const locale = lang === 'ru' ? ru_locale : (lang === 'uk' ? uk_locale : en_locale);

    return (
          <div className="main-banner container">
              <div className="main-banner_left">
                  <h1>{locale['1']}</h1>
                  <div className="main-banner_left-icons">
                      <Link to={"/"+lang+"/fastline"}>
                          <figure>
                              <img src="/images/fastline_icon.svg" alt="fastline icon" />
                              <figcaption itemProp="name">Fastline</figcaption>
                          </figure>
                      </Link>
                      <Link to={"/"+lang+"/business-lounge"}>
                          <figure>
                              <img src="/images/lounge_icon.svg" alt="lounge zone icon" />
                              <figcaption>{locale['2']}</figcaption>
                          </figure>
                      </Link>
                      <Link to={"/"+lang+"/covid-test"}>
                          <figure>
                              <img src="/images/covid_icon.svg" alt="covid-19 test icon" />
                              <figcaption>{locale['3']}</figcaption>
                          </figure>
                      </Link>
                      {/* <Link to="/smart-duty-free">
                          <figure>
                              <img src="/images/shopping_icon.png" alt="shopping icon" />
                              <figcaption>Шоппинг</figcaption>
                          </figure>
                      </Link> */}
                  </div>
                  <HashLink to={"/"+lang+"/#booking-block"} className="main-banner_left-link" smooth>{locale['4']}&nbsp;<span className="font-weight-bold">&#x27F6;</span></HashLink>
               </div>
          </div>
    )
}