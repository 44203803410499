import React, {useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import HomeScreen from './components/HomeScreen';
import ErrorPage from './components/ErrorPage';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import data from './data.js';
import CookieBanner from 'react-cookie-banner';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';

const PartnersScreen = lazy(() => import('./components/PartnersScreen'));
const FastlineScreen = lazy(() => import('./components/FastlineScreen'));
const CovidScreen = lazy(() => import('./components/CovidScreen'));
const LoungeScreen = lazy(() => import('./components/LoungeScreen'));
const AboutScreen = lazy(() => import('./components/AboutScreen'));
const CheckoutForm = lazy(() => import('./components/CheckoutForm'));
const ContactsPage = lazy(() => import('./components/ContactsPage'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsConditions = lazy(() => import('./components/TermsConditions'));
const ServicesTerms = lazy(() => import('./components/ServicesTerms'));

require('dotenv').config();

function App() {
  ReactGA.initialize('UA-206685939-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  
  const ru_locale = {
    1: 'Главная',
    2: 'Для\u00A0партнеров',
    3: 'Контакты',
    4: 'О\u00A0нас',
    5: 'Заказать Fastline',
    6: 'Fastline сделает Ваше путешествие еще удобнее',
    7: 'О\u00A0компании',
    8: 'Правила и условия',
    9: 'Правила предоставления услуг',
    10: 'Политика конфиденциальности',
    11: 'Услуги',
    12: 'Лаунж зона',
    13: 'Covid-19 Тест',
    14: 'Принять cookies',
    15: 'Мы используем обязательные cookie-файлы для работы этого сайта. Мы бы хотели использовать дополнительные cookie для понимания того, как Вы пользуетесь сайтом fastline.aero, сохранения Ваших настроек и улучшения качества авиа-услуг. Мы также используем cookie, установленные другими сайтами, чтоб доносить контент с этих ресурсов.',
    16: 'Fast Line в аэропорту Борисполь - Бизнес-лаунж - Covid-19 Тест',
    17: '✈ Предоставляем услуги в аэропортах ►Fastline.aero◄ ❶ Fast Line (Priority Line) в Борисполе ❷ Бизнес зал Atmosfera Fly ❸ ПЦР, Экспресс Тест в аэропорту'
    // 15: (<><h4 style={{marginBottom:'24px',fontFamily:'Monserrat'}}>Cookies на Fastline </h4><p>Мы используем обязательные cookie-файлы для работы этого сайта. Мы бы хотели использовать дополнительные cookie для понимания того, как Вы пользуетесь сайтом fastline.aero, сохранения Ваших настроек и улучшения качества авиа-услуг. Мы также используем cookie, установленные другими сайтами, чтоб доносить контент с этих ресурсов.</p></>)  
}

const uk_locale = {
  1: 'Головна',
  2: 'Для\u00A0партнерів',
  3: 'Контакти',
  4: 'Про\u00A0нас',
  5: 'Замовити Fastline',
  6: 'Fastline зробить Вашу подорож ще зручнішою',
  7: 'Про\u00A0компанію',
  8: 'Правила та умови',
  9: 'Правила надання послуг',
  10: 'Політика конфіденційності',
  11: 'Послуги',
  12: 'Лаунж зона',
  13: 'Covid-19 Тест',
  14: 'Прийняти cookies',
  15: 'Ми використовуємо обов\'язкові cookie-файли для роботи цього сайту. Ми б хотіли використовувати додаткові cookie для розуміння того, як Ви користуєтеся сайтом fastline.aero, збереження Ваших налаштувань та покращення якості авіа-послуг. Ми також використовуємо cookie, встановлені іншими сайтами, щоб доносити контент з цих ресурсів.',
  16: 'Fast Line в аеропорту Бориспіль - Бізнес-лаунж - Covid-19 Тест',
  17: '✈ Забезпечуємо комфорт в аеропортах ►Fastline.aero◄ ❶ Fast Line (Priority Line) у Борисполі ❷ Бізнес Зал Atmosfera Fly ❸ ПЛР і Експрес Тест в аеропорту'
    // 15: (<><h4 style={{marginBottom:'24px',fontFamily:'Monserrat'}}>Cookies на Fastline </h4><p>Ми використовуємо обов'язкові cookie-файли для роботи цього сайту. Ми б хотіли використовувати додаткові cookie для розуміння того, як Ви користуєтеся сайтом fastline.aero, збереження Ваших налаштувань та покращення якості авіа-послуг. Ми також використовуємо cookie, встановлені іншими сайтами, щоб доносити контент з цих ресурсів.</p></>)  
}
const en_locale = {
  1: 'Home',
  2: 'Partnership',
  3: 'Contacts',
  4: 'About\u00A0us',
  5: 'Order Fastline',
  6: 'Fastline makes your trip more convenient',
  7: 'About\u00A0company',
  8: 'Terms and Conditions',
  9: 'Services Terms',
  10: 'Privacy Policy',
  11: 'Services',
  12: 'Business Lounge',
  13: 'Covid-19 Test',
  14: 'Accept cookies',
  15: 'We use some essential cookies to make this website work. We’d like to set additional cookies to understand how you use fastline.aero, remember your settings and improve airport services. We also use cookies set by other sites to help us deliver content from their services.',
  16: 'Fast Line at Boryspil Airport - Business Lounge - Covid-19 Test',
  17: '✈ We provide services at airports ►Fastline.aero◄ ❶ Fast Line (Priority Line) at Boryspil ❷ Atmosfera Fly Business lounge ❸ PCR, Express Test at the airport'
   // 15: (<><h4 style={{marginBottom:'24px',fontFamily:'Monserrat'}}>Cookies on Fastline </h4><p>We use some essential cookies to make this website work. We’d like to set additional cookies to understand how you use fastline.aero, remember your settings and improve airport services. We also use cookies set by other sites to help us deliver content from their services.</p></>)  
}
  const location = window.location.pathname;
  const [lang, changeLang] = useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : data.lang_default);
  const language = data.languages.some(l => l === location.substr(1, 2)) ? location.substr(1, 2) : lang;
  const [currency, changeCurrency] = useState(localStorage.getItem('currency') ? localStorage.getItem('currency') : data.currency_default);
  const locale = language === 'ru' ? ru_locale : (language === 'uk' ? uk_locale : en_locale);
  const [expanded, setExpanded] = useState(false);
  let cookieNotice = null;
  if (!localStorage.getItem('cookiesAccepted')) {
    cookieNotice = (
       <CookieBanner
          data-nosnippet
          message={locale['15']}
          onAccept={() => {
            localStorage.setItem('cookiesAccepted', true);
          }}
          cookie="user-has-accepted-cookies" 
          dismissOnScroll={false}
          // disableStyle={true}
          styles={{
            banner:{
              // backgroundColor: '#FCFBF8',
              backgroundColor: 'transparent',
              paddingTop: '42px',
              paddingBottom: '42px',
              height: 'auto',
              textAlign: 'left'
            },
            message: {
              color: '#343434',
              fontSize: '16px',
              lineHeight: 'unset',
              fontFamily: 'Open Sans'
            },
            button: {
              backgroundColor: '#000000',
              color: 'white',
              padding: '5px 20px',
              fontSize: '14px',
              borderRadius: '8px',
              fontFamily: 'Open Sans',
              position: 'unset',
              display: 'block',
              top: 'unset',
              right: 'unset',
              marginTop: '25px',
            }
          }}
          buttonMessage={locale['14']}
          className='container'
           />);
    localStorage.clear();
  }
  
  useEffect(() => {
    let pathLang = window.location.pathname.substr(0, 3);
    if(!data.languages.some(l => '/'+l === pathLang)) {
      let newPathname = '/'+lang+window.location.pathname;
      window.location.href=window.location.origin+newPathname+window.location.hash;
    };
  });

  useEffect(() => {
    if (!localStorage.getItem('cookiesAccepted')) {
      localStorage.clear();
    }
  }, []);

  function setNewLang(lang) {
    changeLang(lang);
    localStorage.setItem('lang', lang);
    let pathLang = window.location.pathname.substr(0, 3);
    if(data.languages.some(l => '/'+l === pathLang)) {
      let newPathname = '/'+lang+window.location.pathname.substr(3);
      window.location.href=window.location.origin+newPathname+window.location.hash;
    };
  }

  function setNewCurrency(curr) {
    let currency = curr.split(' ')[1].toUpperCase();
    changeCurrency(currency);
    localStorage.setItem('currency', currency);
    localStorage.removeItem('cart');
    window.location.reload(true);
        // showCart(false);
        // if (modalShow) { setModalShow(false); }
  }

  let curr_array = [];
  for (let curr in data.currencies) {
    curr_array.push([curr, data.currencies[curr]]);
  }
  
  return (
    <HelmetProvider>
    <BrowserRouter >
        <Helmet>
          <html lang={language} />
          <title>{locale['16']}</title>
          <meta name="description" content={locale['17']} />
          <link rel='canonical' href={`https://fastline.aero${location}`} />
          <link rel="alternate" href={`https://fastline.aero/uk${location.substr(3)}`} hreflang='uk'></link>
          <link rel="alternate" href={`https://fastline.aero/ru${location.substr(3)}`} hreflang='ru'></link>
          <link rel="alternate" href={`https://fastline.aero/en${location.substr(3)}`} hreflang='en'></link>
          <link rel="alternate" href={`https://fastline.aero/uk${location.substr(3)}`} hreflang="x-default"/>
          <meta property="og:url" content="https://fastline.aero" data-rh="true" />
          <meta property="og:title" content={locale['16']} data-rh="true" />
          <meta property="og:description" content={locale['17']} data-rh="true" />
          <meta name="twitter:domain" content="https://fastline.aero" data-rh="true" />
          <meta name="twitter:title" content={locale['16']} data-rh="true" />
          <meta name="twitter:description" content={locale['17']} data-rh="true" />
        </Helmet>
        <div className="root-inner" itemScope itemType="https://schema.org/Organization">
        {cookieNotice}
        <header id="header">
          <Navbar expand="lg" className="container py-0" expanded={expanded}>
            <Navbar.Brand href={`/${lang}`} className="pl-0 navbar-brand" itemProp="url" onClick={() => setExpanded(false)}>
              <img className="" src="/images/Logo_Black.svg" alt="Fastline logo" itemProp="logo"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Link to={"/"+language} onClick={() => setExpanded(false)} className='nav-link'>{locale['1']}</Link>
                <Link to={"/"+language+"/partners"} onClick={() => setExpanded(false)} className='nav-link'>{locale['2']}</Link>
                <Link to={"/"+language+"/contacts"} onClick={() => setExpanded(false)} className='nav-link'>{locale['3']}</Link>
                <Link to={"/"+language+"/about"} onClick={() => setExpanded(false)} className='nav-link'>{locale['4']}</Link>
                <div className="d-flex menu-selects px-0">
                <Form.Group controlId="exampleForm.ControlSelect2" className="mr-4">
                      {/* <Form.Label className="d-inline mx-1">Валюта</Form.Label> */}   
                      <Form.Control as="select" onChange={(e)=>{setNewCurrency(e.target.value.toLowerCase());} } value={data.currencies[currency]+' '+currency}>
                        { curr_array.map(c => (
                            <option key={`key-${c}`}>{c[1]+' '+c[0]}</option>
                         )) }
                      </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    {/* <Form.Label className="d-inline mx-1">Язык</Form.Label> */}
                    <Form.Control as="select" onChange={(e)=>{setNewLang(e.target.value.toLowerCase());} } value={language.toUpperCase()} className="d-inline w-auto mx-1">
                      {data.languages.map(l => (
                        <option key={`key-${l}`}>{l.toUpperCase()}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
               </Nav>
            </Navbar.Collapse>
            <HashLink to={`/${lang}/#booking-block`} onClick={() => setExpanded(false)} className="cart" smooth>
              <Button>
              <span className="cart-text">{locale['5']}</span>
              <img className="cart_icon lg_screen" src="/images/cart.svg" alt="cart icon" />
              <img className="cart_icon sm_screen" src="/images/cart_black.svg" alt="cart icon" />
              </Button>
            </HashLink>
          </Navbar>
        </header>
        <main>
        <Suspense fallback={
          <div className="d-flex justify-content-center my-5">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
        }>
        <Switch>
        <Route path="/" component={HomeScreen} exact></Route>
        <Route path="/:lang" component={HomeScreen} exact></Route>
        <Route path="/:lang/partners" component={PartnersScreen} exact></Route>
        <Route path="/:lang/fastline" component={FastlineScreen} exact></Route>
        <Route path="/:lang/covid-test" component={CovidScreen} exact></Route>
        <Route path="/:lang/business-lounge" component={LoungeScreen} exact></Route>
        <Route path="/:lang/checkout" component={CheckoutForm} exact></Route>
        <Route path="/:lang/contacts" component={ContactsPage} exact></Route>
        <Route path="/:lang/about" component={AboutScreen} exact></Route>
        <Route path="/:lang/privacy-policy" component={PrivacyPolicy} exact></Route>
        <Route path="/:lang/terms-and-conditions" component={TermsConditions} exact></Route>
        <Route path="/:lang/service-terms" component={ServicesTerms} exact></Route>
        <Route component={ErrorPage} />
        </Switch>
        </Suspense>
        </main>
        <footer className="">
        <div className="container py-5">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <Nav.Link href={"/"+language}>
              <img className="" src="/images/Logo_White.svg" alt="Fastline logo" loading='lazy' />
            </Nav.Link>
            <p className="font-weight-bolder my-4 py-1">{locale['6']}</p>
            <p className="copyrights copyrights-desk">Copyright {new Date().getFullYear()} Fastline.aero</p>
          </div>

          <div className="col-xs-6 col-md-3">
            <p className="footer-subtitle">{locale['7']}</p>
            <ul className="footer-links">
            <li><Link to={"/"+language+"/about"}>{locale['4']}</Link></li>
              <li><Link to={"/"+language+"/terms-and-conditions"}>{locale['8']}</Link></li>
              <li><Link to={"/"+language+"/service-terms"}>{locale['9']}</Link></li>
              <li><Link to={"/"+language+"/privacy-policy"}>{locale['10']}</Link></li>
            </ul>
          </div>

          <div className="col-xs-6 col-md-3">
            <p className="footer-subtitle">{locale['11']}</p>
            <ul className="footer-links">
              <li><Link to={"/"+language+"/fastline"}>Fastline</Link></li>
              <li><Link to={"/"+language+"/business-lounge"}>{locale['12']}</Link></li>
              <li><Link to={"/"+language+"/covid-test"}>{locale['13']}</Link></li>
              <li><a href="https://smartdutyfree.com/" target="_blank" rel="noreferrer">Smart Duty Free &nbsp;<img src="/images/external_link.svg" alt="external link icon" /></a></li>
            </ul>
            <p className="copyrights copyrights-mob">Copyright {new Date().getFullYear()} Fastline.aero</p>
          </div>
        </div>
        <hr/>
      </div>
        </footer>
      </div>
    </BrowserRouter>
  </HelmetProvider>
  );
}
export default App;